import React, { CSSProperties } from "react";
import { Form, Switch, Radio, Button, Card, Flex as AntFlex } from "antd";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;

  .ant-form-item {
    flex: 1;
    margin-bottom: 8px;
  }

  .ant-form-item .ant-form-item-label {
    padding: 0;
  }
  .ant-form-item .ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-radio-group .ant-radio {
    zoom: 1.5;
  }
  .switch-label .switch-button {
    zoom: 1.1;
  }

  .ant-radio-group .ant-radio-wrapper span, .switch-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-radio-group .ant-radio-wrapper span {
    font-weight: 500;
  }

  .ant-radio-wrapper span {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

const Section = styled(Card)`
  .ant-card-head {
    background: #E3E9ED;
    .ant-card-head-title {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 0;
  }

`;

const Flex = styled(AntFlex)`
  align-self: stretch;
  gap: 16px;
`;

const SwitchLabelStyled = styled.label`
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
display: flex;
gap: 16px;
align-self: stretch;
flex: 1;

&:hover {
  cursor: pointer;
}
`;

const SwitchBtn = ({ value, onChange, label }: { value?: boolean, onChange?: (value: boolean) => void; label: string }) => (
  <SwitchLabelStyled className="switch-label">
    <Switch className="switch-button" checked={value} onChange={onChange} /> {label}
  </SwitchLabelStyled>
);

const SwitchLabel = ({ name, label }: { name: string, label: string }) => (
  <Form.Item name={[name, 'enable']} className={name}>
    <SwitchBtn label={label} />
  </Form.Item>
);

const RadioLabel = ({ name, label, options, style }: { name: string, label?: string, options: { value: string, label: string }[], style?: CSSProperties }) => (
  <Form.Item name={[name, 'value']} label={label} className={name} style={style}>
    <Radio.Group>
      {options.map((option, i) => (
        <Radio value={option.value} style={i === 0 ? { marginRight: 48 } : {}}>{option.label}</Radio>
      ))}
    </Radio.Group>
  </Form.Item>
);

const settingConfigs = {
  "UI/VERSION": { name: "UI/VERSION", key: "VERSION", type: "UI" },
  "REPORT/LABEL_CONFIG": { name: "REPORT/LABEL_CONFIG", key: "LABEL_CONFIG", type: "REPORT" },
  "HOME/VOICE_CALL_STAFF": { name: "HOME/VOICE_CALL_STAFF", key: "VOICE_CALL_STAFF", type: "HOME" },
  "HOME/CHOOSE_STAFF_QUEUE": { name: "HOME/CHOOSE_STAFF_QUEUE", key: "CHOOSE_STAFF_QUEUE", type: "HOME" },
  "RECIEPT/SEND_SMS_CUSTOMER": { name: "RECIEPT/SEND_SMS_CUSTOMER", key: "SEND_SMS_CUSTOMER", type: "RECIEPT" },
  "HOME/SHOW_AVERAGE_TICKET": { name: "HOME/SHOW_AVERAGE_TICKET", key: "SHOW_AVERAGE_TICKET", type: "HOME" },
  "HOME/DISPLAY_CALL_PHONE_STILL_WAITING": { name: "HOME/DISPLAY_CALL_PHONE_STILL_WAITING", key: "DISPLAY_CALL_PHONE_STILL_WAITING", type: "HOME" },
  "REPORT/SMS_EMAIl_REPORT_SALON_DAILY": { name: "REPORT/SMS_EMAIl_REPORT_SALON_DAILY", key: "SMS_EMAIl_REPORT_SALON_DAILY", type: "REPORT" },
  "CHECK_IN/SHOW_HIDE_PRICE": { name: "CHECK_IN/SHOW_HIDE_PRICE", key: "SHOW_HIDE_PRICE", type: "CHECK_IN" },
  "CHECK_IN/SHOW_POINT_CUSTOMER": { name: "CHECK_IN/SHOW_POINT_CUSTOMER", key: "SHOW_POINT_CUSTOMER", type: "CHECK_IN" },
  "CHECK_IN/SKIP_CHOOSE_STAFF": { name: "CHECK_IN/SKIP_CHOOSE_STAFF", key: "SKIP_CHOOSE_STAFF", type: "CHECK_IN" },
  "CHECK_IN/VOICE_NOTIFICATION_STAFF": { name: "CHECK_IN/VOICE_NOTIFICATION_STAFF", key: "VOICE_NOTIFICATION_STAFF", type: "CHECK_IN" },
  "CHECK_IN/SKIP_CHOOSE_APP_WALK_IN": { name: "CHECK_IN/SKIP_CHOOSE_APP_WALK_IN", key: "SKIP_CHOOSE_APP_WALK_IN", type: "CHECK_IN" },
  "CHECK_IN/ENABLE_SIMPLE_MENU": { name: "CHECK_IN/ENABLE_SIMPLE_MENU", key: "ENABLE_SIMPLE_MENU", type: "CHECK_IN" },
  "CHECK_IN/DISPLAY_INFO_CUSTOMER": { name: "CHECK_IN/DISPLAY_INFO_CUSTOMER", key: "DISPLAY_INFO_CUSTOMER", type: "CHECK_IN" },
  "CHECK_IN/SKIP_CHOOSE_SERVICE_CHECK_IN": { name: "CHECK_IN/SKIP_CHOOSE_SERVICE_CHECK_IN", key: "SKIP_CHOOSE_SERVICE_CHECK_IN", type: "CHECK_IN" },
  "PAYMENT/MULTIPLE_PAY_CARD": { name: "PAYMENT/MULTIPLE_PAY_CARD", key: "MULTIPLE_PAY_CARD", type: "PAYMENT" },
  "PAYMENT/MODAL_TIP": { name: "PAYMENT/MODAL_TIP", key: "MODAL_TIP", type: "PAYMENT" },
  "PAYMENT/SHOW_UI_STAFF_CASHIER": { name: "PAYMENT/SHOW_UI_STAFF_CASHIER", key: "SHOW_UI_STAFF_CASHIER", type: "PAYMENT" },
  "PAYMENT/AUTO_SHOW_ADD_PRICE": { name: "PAYMENT/AUTO_SHOW_ADD_PRICE", key: "AUTO_SHOW_ADD_PRICE", type: "PAYMENT" },
  "PAYMENT/VERIFY_PASS_DISCOUNT": { name: "PAYMENT/VERIFY_PASS_DISCOUNT", key: "VERIFY_PASS_DISCOUNT", type: "PAYMENT" },
  "PAYMENT/SHOW_SUPPLY_FEE_CASHIER": { name: "PAYMENT/SHOW_SUPPLY_FEE_CASHIER", key: "SHOW_SUPPLY_FEE_CASHIER", type: "PAYMENT" },
  "PAYMENT/FLOW_ADD_TIP": { name: "PAYMENT/FLOW_ADD_TIP", key: "FLOW_ADD_TIP", type: "PAYMENT" },
  "TICKET/PRINT_VOIDED": { name: "TICKET/PRINT_VOIDED", key: "PRINT_VOIDED", type: "TICKET" },
  "CHECK_IN/PRINT_AFTER_CHECK_IN": { name: "CHECK_IN/PRINT_AFTER_CHECK_IN", key: "PRINT_AFTER_CHECK_IN", type: "CHECK_IN" },
  "PRINT/PRINT_OWNER_NO_RECEIPT": { name: "PRINT/PRINT_OWNER_NO_RECEIPT", key: "PRINT_OWNER_NO_RECEIPT", type: "PRINT" },
  "PRINT/DOUBLE_PRINT_RECEIPT": { name: "PRINT/DOUBLE_PRINT_RECEIPT", key: "DOUBLE_PRINT_RECEIPT", type: "PRINT" },
  "PRINT/AUTO_CUT_PAPER": { name: "PRINT/AUTO_CUT_PAPER", key: "AUTO_CUT_PAPER", type: "PRINT" },
  "PRINT/DISPLAY_PRINT_CHECK": { name: "PRINT/DISPLAY_PRINT_CHECK", key: "DISPLAY_PRINT_CHECK", type: "PRINT" },
  "PRINT/DISPLAY_OPTION_CASH": { name: "PRINT/DISPLAY_OPTION_CASH", key: "DISPLAY_OPTION_CASH", type: "PRINT" },
  "TURN/TURN_MANUAL": { name: "TURN/TURN_MANUAL", key: "TURN_MANUAL", type: "TURN" },
  "TURN/SPECIAL": { name: "TURN/SPECIAL", key: "SPECIAL", type: "TURN" },
  "TURN/STAFF_SORT_RATE_AMOUNT": { name: "TURN/STAFF_SORT_RATE_AMOUNT", key: "STAFF_SORT_RATE_AMOUNT", type: "TURN" },
  // "TURN/VOICE_NEXT_TURN": { name: "TURN/VOICE_NEXT_TURN", key: "VOICE_NEXT_TURN", type: "TURN" },
  "TURN/SYNC_BILL_MANUAL_TURN": { name: "TURN/SYNC_BILL_MANUAL_TURN", key: "SYNC_BILL_MANUAL_TURN", type: "TURN" },
  "TURN/SPECIAL_APPOINTMENT": { name: "TURN/SPECIAL_APPOINTMENT", key: "SPECIAL_APPOINTMENT", type: "TURN" }
};
export const settingConfigArray = Object.values(settingConfigs);

const FormSettingConfig: React.FC = () => {
  return (
    <Container>
      {settingConfigArray.map(o => (<>
        <Form.Item noStyle name={o.name} />
        <Form.Item noStyle name={[o.name, 'enable']} />
        <Form.Item noStyle name={[o.name, 'id']} />
        <Form.Item noStyle name={[o.name, 'key']} />
        <Form.Item noStyle name={[o.name, 'type']} />
        <Form.Item noStyle name={[o.name, 'value']} />
      </>))}
      {/* General Settings */}
      <Section title="General" style={{ gridColumn: '1 / 3' }}>
        <Flex>
          <RadioLabel name="UI/VERSION" label="Version UI" options={[
            { value: "V1", label: "Ver. 01" },
            { value: "V2", label: "Ver. 02" }
          ]} />
          <RadioLabel name='REPORT/LABEL_CONFIG' label="Label cash/bonus report receipt" options={[
            { value: "Cash", label: "Cash" },
            { value: "Bonus", label: "Bonus" }
          ]} />
        </Flex>
        <Flex>
          <div style={{ flex: 1 }}>
            <SwitchLabel name="HOME/VOICE_CALL_STAFF" label={'Display voice call the staff home menu'} />
            <SwitchLabel name="HOME/CHOOSE_STAFF_QUEUE" label={'Select the staff in the queue section'} />
          </div>
          <div style={{ flex: 1 }}>
            <SwitchLabel name="RECIEPT/SEND_SMS_CUSTOMER" label={'Send sms to review'} />
            <SwitchLabel name="HOME/SHOW_AVERAGE_TICKET" label={'Display average ticket'} />
          </div>
          <div style={{ flex: 1 }}>
            <SwitchLabel name="HOME/DISPLAY_CALL_PHONE_STILL_WAITING" label={'Display phone call customer still waiting'} />
            <SwitchLabel name="REPORT/SMS_EMAIl_REPORT_SALON_DAILY" label={'Display Auto Send Report Daily'} />
          </div>
        </Flex>
      </Section>

      {/* Check In */}
      <Section title="Check In">
        <Flex>
          <SwitchLabel name="CHECK_IN/SHOW_HIDE_PRICE" label={'Price display'} />
          <SwitchLabel name="CHECK_IN/SHOW_POINT_CUSTOMER" label={'Point display'} />
        </Flex>
        <Flex>
          <SwitchLabel name="CHECK_IN/SKIP_CHOOSE_STAFF" label={'Skip select the staff'} />
          <SwitchLabel name="CHECK_IN/VOICE_NOTIFICATION_STAFF" label={'Voice call to the staff'} />
        </Flex>
        <Flex>
          <SwitchLabel name="CHECK_IN/SKIP_CHOOSE_APP_WALK_IN" label={'Display Walk/Appt'} />
          <SwitchLabel name="CHECK_IN/ENABLE_SIMPLE_MENU" label={'Simple menu display'} />
        </Flex>
        <Flex>
          <SwitchLabel name="CHECK_IN/DISPLAY_INFO_CUSTOMER" label={'Display customer information'} />
          <SwitchLabel name="CHECK_IN/SKIP_CHOOSE_SERVICE_CHECK_IN" label={'By pass service menu'} />
        </Flex>
      </Section>

      {/* Payment */}
      <Section title="Payment">
        <Flex>
          <SwitchLabel name="PAYMENT/MULTIPLE_PAY_CARD" label={'Multiple card'} />
          <SwitchLabel name="PAYMENT/MODAL_TIP" label={'Tip display'} />
        </Flex>
        <Flex>
          <SwitchLabel name="PAYMENT/SHOW_UI_STAFF_CASHIER" label={'Staff on cashier'} />
          <SwitchLabel name="PAYMENT/AUTO_SHOW_ADD_PRICE" label={'Add price display'} />
        </Flex>
        <Flex>
          <SwitchLabel name="PAYMENT/VERIFY_PASS_DISCOUNT" label={'Passcode for discount'} />
          <SwitchLabel name="PAYMENT/SHOW_SUPPLY_FEE_CASHIER" label={'Supply fee display'} />
        </Flex>
        <RadioLabel
          style={{ marginTop: '8px' }}
          name="PAYMENT/FLOW_ADD_TIP" label="Version TIP screen display order"
          options={[
            { value: "TIP_BEFORE_SALE", label: "Tip At the Point of Sale" },
            { value: "TIP_AFTER_SALE", label: "Tip After The Point of Sale" }
          ]} />
      </Section>

      {/* Print */}
      <Section title="Print">
        <Flex>
          <SwitchLabel name="TICKET/PRINT_VOIDED" label={'Auto print voided ticket'} />
          <SwitchLabel name="CHECK_IN/PRINT_AFTER_CHECK_IN" label={'Printing QR ticket'} />
        </Flex>
        <Flex>
          <SwitchLabel name="PRINT/PRINT_OWNER_NO_RECEIPT" label={'No Copy of Merchant receipts'} />
          <SwitchLabel name="PRINT/DOUBLE_PRINT_RECEIPT" label={'Double receipts'} />
        </Flex>
        <Flex>
          <SwitchLabel name="PRINT/AUTO_CUT_PAPER" label={'Auto cut paper'} />
          <SwitchLabel name="PRINT/DISPLAY_PRINT_CHECK" label={'Print Check display'} />
        </Flex>
        <Flex>
          <SwitchLabel name="PRINT/DISPLAY_OPTION_CASH" label={'Display No Reciept Payment Cash'} />
        </Flex>
      </Section>

      {/* Setting Turn */}
      <Section title="Setting Turn">
        <Flex>
          <SwitchLabel name="TURN/TURN_MANUAL" label={'Manual display'} />
          <SwitchLabel name="TURN/SPECIAL" label={'Turn system specialize'} />
        </Flex>
        <Flex>
          <SwitchLabel name="TURN/STAFF_SORT_RATE_AMOUNT" label={'Sort turn system by rate amounts'} />
          {/* <SwitchLabel name="TURN/VOICE_NEXT_TURN" label={'Voice next turn'} /> */}
        </Flex>
        <SwitchLabel name="TURN/SYNC_BILL_MANUAL_TURN" label={'Auto turn & manual turn'} />
        <SwitchLabel name="TURN/SPECIAL_APPOINTMENT" label={'Turn by appointment'} />
      </Section>
    </Container>
  );
};

export default FormSettingConfig;
